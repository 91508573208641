<template>
  <navi-bar>
    <img src="@/assets_mobile/img/headin_off.png"/>
    <span>{{$t('front.common.depositApply')}}<em>Deposit</em></span>
  </navi-bar>
  <section class="sub-box">
    <template v-if="type == 'apply'">
      <div class="state"><i>입금 계좌 정보 확인</i> : 입금 계좌번호는 쪽지로 발송되며, 발송한 계좌번호로 선입금 바랍니다.</div>
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
        <a class="acountbtn"  @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>
      </div>
      <div class="topbox">
         <img class="img" src="@/assets_mobile/img/img-coin.png" />
         <div class="appliwrite">
           <!--p class="outmoney">{{$t('front.board.processMoney')}}</p-->
           <input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.give.moneyInput')" v-model="setCurrentChargeMoney"/>
           <i>원</i></div>
         <ul class="applihave">
           <li><img src="@/assets_mobile/img/icon_exclamat.png" /></li>
           <li>{{$t('front.cash.moneyHave')}} :</li>
           <li class="">{{thousand(userData.cashAmt)}}{{$t('front.common.money')}}</li>
         </ul>
         <ul class="applimoneybtn">
           <li class=""><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
           <li class=""><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
           <li class=""><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
           <li class="bgc"><a @click="setCurrentChargeMoney = ''">정정</a></li>

         </ul>
      </div>
      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyCheckListE')}}</h3>
          <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p>
          <!--div class="btnwrap">
            <a class="appli" @click="getAcc">계좌번호 신청</a>
          </div-->
          <ul class="applistep">
            <li><em>1</em><span>{{$t('front.cash.we')}} <strong>{{$t('front.cash.moneyCheckListG')}}</strong>{{$t('front.cash.moneyCheckListH')}}</span></li>
            <li><em>2</em><span>{{$t('front.cash.moneyCheckListI')}}</span></li>
            <li><em>3</em><span><strong>{{$t('front.cash.moneyInput')}}</strong>{{$t('front.cash.moneyCheckListK')}} <strong>{{$t('front.cash.moneyCheckListL')}}</strong>{{$t('front.cash.moneyCheckListM')}}</span></li>
          </ul>
        </div>

        <!--ul class="appliinput">
          <li>보너스 금액</li>
          <li class="bbl">{{thousand(bonus)}}</li>
        </ul-->
        <!--ul class="appliinput">
          <li>보너스 혜택</li>
          <li class="btnwrap m0">
            <a class="all"><input type="checkbox" :checked="isApplyBonus === true" @click="isApplyBonus = true"/>받기</a>
            <a class="selec"><input type="checkbox" :checked="isApplyBonus === false" @click="isApplyBonus = false"/>받지않기</a>
          </li>
        </ul-->
      </div>

      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="moneyread">
        <h3>NOTICE</h3>
        <ul>
          <li><span>1. {{$t('front.cash.moneyCheckListA')}}</span></li>
          <li><span>2. {{$t('front.cash.moneyCheckListB')}}</span></li>
          <li><span>3. {{$t('front.cash.moneyCheckListC')}}</span></li>
          <li><span>4. {{$t('front.cash.moneyCheckListD')}}</span></li>
        </ul>
      </div>
      <div class="applylistbtn list">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.cash.depositCheck')}} ></a>
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>입금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>입금내역</span></a>
      </div-->
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail">
              <li class="date"><em>{{computedCashStatus(item.cashStatus).text}}</em> ({{dateFormat(item.regDate)}})</li>
              <li><a class="close">{{$t('front.board.delete')}}</a></li>
              <!--li><span>보너스금액</span>:<span class="rd">{{thousand(item.preCashAmt)}}</span></li-->
            </ul>
            <ul class="gamebetdetail">
              <li>{{$t('front.board.processMoney')}}</li>
              <li><span class="gn">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
              <!--li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li-->
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a-->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>

      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn list">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"> &lt; {{$t('front.common.depositApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Charge from '@/views/member/money/charge'

export default {
  name: 'charge',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Charge
  ]
}
</script>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>

<style>
.morebtn {margin-bottom: 10px !important;}
</style>
